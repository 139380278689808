import { Injectable } from '@angular/core';
import { UserGradeEnum } from '../_utils/enums/user-grade.enum';
import { USER_GRADE_SETTINGS } from '../user-grade/user-grade.settings';
import { TranslateService } from '@ngx-translate/core';
import { ModuleEnum } from '../_utils/unums/module.enum';
import { UserJourneyService } from './user-journey.service';
import { Storage } from '@ionic/storage-angular';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UserGradeService {
  private userGradeStrategy: any;

  constructor(
    private translateService: TranslateService,
    private userJourneyService: UserJourneyService,
    private storage: Storage,
    private firebaseService: FirebaseService
  ) {
    this.initUserGradeStrategy();
  }

  public async userGradeStrategyHandler(module: ModuleEnum, key: string): Promise<any> {
    const settings = USER_GRADE_SETTINGS
      .find(x => x.module === module && x.key === key);
    return await this.userGradeStrategy[key](settings, module);
  }

  public async saveUserGrade(module: ModuleEnum | string, payload: { [key: string]: any }): Promise<void> {
    await this.userJourneyService.updateUserJourney(`modules/${module}`, payload);
  }

  public async hasUserGrade(module: ModuleEnum | string): Promise<boolean> {
    const userJourney = await this.storage.get('user_journey');
    const userModule = userJourney?.modules?.[module];
    return !!userModule?.grade;
  }

  private initUserGradeStrategy() {
    this.userGradeStrategy = {
      ['pre_training']: async (settings: any, module: ModuleEnum) => {
        const userAssessments = await this.getUserAssessments(module, 'pre_training');
        const userGrade = await this.generateUserGradeTraining(settings, userAssessments);
        await this.saveUserGrade(module, {grade: userGrade.grade, ['grade_avg']: userGrade.avg});
        return userGrade;
      },
      ['post_training']: async (settings: any, module: ModuleEnum) => {
        const userAssessments = await this.getUserAssessments(module, 'post_training');
        const userGrade = await this.generateUserGradeTraining(settings, userAssessments);
        await this.saveUserGrade(module, {['final_grade']: userGrade.grade, ['final_grade_avg']: userGrade.avg});
        return userGrade;
      }
    };
  }

  private async generateUserGradeTraining(settings: any, userAssessments: any[]): Promise<any> {
    const generalAVG = this.getAverageBySection(settings, 'general', userAssessments);
    const groups = settings.groups.filter(x => x.key !== 'general');
    let avg = generalAVG;
    for (const group of groups) {
      avg += this.getAverageBySection(settings, group.key, userAssessments);
    }
    const globalAVG = avg / (groups.length + 1);
    const userQualification = generalAVG >= globalAVG ? generalAVG : globalAVG;
    const userGrade: any = {
      avg: globalAVG
    };
    if (userQualification >= 7) {
      userGrade.grade = UserGradeEnum.advance;
    } else if (userQualification >= 5) {
      userGrade.grade = UserGradeEnum.medium;
    } else {
      userGrade.grade = UserGradeEnum.beginner;
    }
    userGrade.content = settings.grades.find(x => x.key === userGrade.grade);
    userGrade.urlVideo = userGrade.content.urlVideo[this.translateService.currentLang];
    return userGrade;
  }

  private computeAverage(items: any[], base: number) {
    return base * items.reduce((prev, item) => prev + (item.value * item.weight), 0) / (100);
  }

  private getAverageBySection(settings: any, sectionKey: string, userAssessments: any[]) {
    const generalItems = settings.groups.find(x => x.key === sectionKey)?.items;
    const general = userAssessments.filter(x => generalItems.find(y => y === x.key));
    return this.computeAverage(general, 5 / 3);
  }

  private async getUserAssessments(module: ModuleEnum, key: string): Promise<any[]> {
    const assessments = await this.firebaseService.getDocumentsListOnce(`assessments/${module}/${key}`);
    const user = await this.storage.get('user');
    const userAssessments = await this.firebaseService.getDocumentsListOnce(`user_assessments/${user.uid}/${module}/${key}`);
    const result = [];
    // @ts-ignore
    for (const userAssessmentKey in userAssessments[0]) {
      if (!userAssessmentKey.startsWith('-')) {
        continue;
      }
      const assessment = assessments.find(x => x.id === userAssessmentKey);
      if (!assessment) {
        continue;
      }
      result.push({
        key: userAssessmentKey,
        value: userAssessments[0][userAssessmentKey],
        weight: assessment.weight || 0
      });
    }
    return result;
  }
}
