import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { TutorialService } from '../_services/tutorial.service';
import { UserJourneyService } from '../_services/user-journey.service';
import { UserDemoGuard } from './user-demo.guard';
import { distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuidedVisitGuard implements CanActivate {
  private visitGuidedHandler$: EventEmitter<string>;

  constructor(
    private storage: Storage,
    private tutorialService: TutorialService,
    private userJourneyService: UserJourneyService,
    private userDemoGuard: UserDemoGuard
  ) {
    this.visitGuidedHandler$ = new EventEmitter<string>();
    this.visitGuidedHandler$.pipe(distinctUntilChanged()).subscribe((url) => {
      this.tutorialService.urlHandler(url).then();
    })
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isDemo = await this.userJourneyService.isDemo();
    this.tutorialService.isGuidedVisit = await this.storage.get('guided_visit');
    const visitGuidedHandler = async (url: string) => {
      if (this.tutorialService.isGuidedVisit) {
        this.visitGuidedHandler$.emit(url);
      }
    };
    if (isDemo && route.routeConfig.path === 'dashboard') {
      await this.userDemoGuard.canActivate(route, state);
      await visitGuidedHandler('/');
      return false;
    }
    this.tutorialService.isGuidedVisit = await this.storage.get('guided_visit');
    if (isDemo && this.tutorialService.isGuidedVisit && route.routeConfig.path === ':module/simulations-list') {
      await visitGuidedHandler('/');
    } else if (this.tutorialService.isGuidedVisit) {
      await visitGuidedHandler(state.url);
    }
    return true;
  }
}
