import Crunker from 'crunker';

export class GenericFunctions {
  public static processObject(payload: any, omittedPrefix = '-'): any {
    const result = {};
    for (const key in payload) {
      if(!key.startsWith(omittedPrefix)) {
        continue;
      }
      result[key] = payload[key];
    }
    return GenericFunctions.isEmpty(result)? null : result;
  }

  public static isEmpty(obj: any): boolean {
    if (!obj) {
      return true;
    }
    // eslint-disable-next-line guard-for-in
    for (const _ in obj) {
     return false;
    }
    return true;
  }

  public static awaitTime(time: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => resolve(), time);
    });
  }

  public static async onLoadData(audio: any): Promise<void> {
    return new Promise(resolve => {
      audio.onloadeddata = () =>  {
        resolve();
      };
    });
  }

  public static async onLoadDuration(video: any): Promise<void> {
    return new Promise(resolve => {
      if(video.duration !== Infinity) {
        return resolve();
      }
      video.currentTime = 1e101;
      setTimeout(() => {
        video.currentTime = 0;
        resolve();
      }, 1500);
    });
  }

  public static async joinAudios(audios: string[]): Promise<any> {
    const crunker = new Crunker();
    const audioBuffers = await crunker.fetchAudio(...audios);
    const audioBuffer = crunker.concatAudio(audioBuffers);
    const audio = crunker.export(audioBuffer, 'audio/mp3');
    return audio;
  }

  public static createSilentAudio(time, freq = 44100){
    const length = Math.round(time * freq);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const AudioContext = window.AudioContext;
    const context = new AudioContext();
    const audioFile = context.createBuffer(1, length, freq);
    return URL.createObjectURL(this.bufferToWave(audioFile, length));
  }

  public static blobToDataURL(blob: Blob): Promise<any> {
    return new Promise(resolve => {
      const a = new FileReader();
      a.onload = (e) => {
        resolve(e.target.result);
      };
      a.readAsDataURL(blob);
    });
  }

  public static isNullOrUndefined(value: string | number): boolean {
    return value === null || value === undefined;
  }

  public static validateUrl(url: string, configUrl: string): boolean {
    const fragments = url.split('?')[0].split('/').filter((fragment: string) => fragment);
    const configFragments = configUrl.split('/').filter((fragment: string) => fragment);
    if(fragments.length !== configFragments.length) {
      return false;
    }
    let isValidUrl = true;
    for (let i = 0; i < fragments.length; i++) {
      if(configFragments[i].startsWith(':')) {
        continue;
      }
      if(fragments[i] !== configFragments[i]) {
        isValidUrl = false;
        break;
      }
    }
    return isValidUrl;
  }

  private static bufferToWave(abuffer, len) {
    // eslint-disable-next-line prefer-const
    let numOfChan = abuffer.numberOfChannels,
      // eslint-disable-next-line prefer-const
      length = len * numOfChan * 2 + 44,
      // eslint-disable-next-line prefer-const
      buffer = new ArrayBuffer(length),
      // eslint-disable-next-line prefer-const
      view = new DataView(buffer),
      // eslint-disable-next-line prefer-const
      channels = [], i, sample,
      offset = 0,
      pos = 0;

    const setUint16 = (data) => {
      view.setUint16(pos, data, true);
      pos += 2;
    };

    const setUint32 = (data) => {
      view.setUint32(pos, data, true);
      pos += 4;
    };

    // write WAVE header
    setUint32(0x46464952);
    setUint32(length - 8);
    setUint32(0x45564157);

    setUint32(0x20746d66);
    setUint32(16);
    setUint16(1);
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    setUint32(abuffer.sampleRate * 2 * numOfChan);
    setUint16(numOfChan * 2);
    setUint16(16);

    setUint32(0x61746164);
    setUint32(length - pos - 4);

    // write interleaved data
    for(i = 0; i < abuffer.numberOfChannels; i++){
      channels.push(abuffer.getChannelData(i));
    }

    while(pos < length) {
      for(i = 0; i < numOfChan; i++) {             // interleave channels
        sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        // eslint-disable-next-line no-bitwise
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
        view.setInt16(pos, sample, true);          // write 16-bit sample
        pos += 2;
      }
      offset++; // next source sample
    }

    // create Blob
    return new Blob([buffer], {type: 'audio/wav'});
  }
}
