interface String {
  format(...args): string;
  template(...args): string;
  truncate(...args): string;
  toCamelCase(): string;
}

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/\{(\d+)}/g, function (match, number) {
    return typeof args[number - 1] != 'undefined'
      ? args[number - 1]
      : match;
  });
};

String.prototype.template = function (args) {
  let str = '';
  Object.keys(args).forEach(key => {
    let exp = `\{\{${key}\}\}`;
    const regex =  new RegExp(exp,'g');
    str = this.replace(regex, args[key]);
  });
  return str;
};

String.prototype.toCamelCase = function () {
  return this.replace(/^(.)/, function($1) { return $1.toUpperCase(); })
  .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })  
 
};

String.prototype.truncate = function () {
  var max = arguments[0];
  var index = -1;
  if (this.length > max) index = this.indexOf(' ', max);
  return index != -1 ? (this.substr(0, index) + '...') : this; 
};