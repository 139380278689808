import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { UserGradeService } from '../_services/user-grade.service';
import { SettingsService } from '../_services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleGuard implements CanActivate {

  constructor(
    private storage: Storage,
    private userGradeService: UserGradeService,
    private settingsService: SettingsService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const module = route.params?.module;
    const userJourney = await this.storage.get('user_journey');
    const userModule = userJourney?.modules?.[module];
    if (!userModule) {
      return true;
    }
    if (userModule.grade) {
      return true;
    }
    const moduleSetting = await this.settingsService.getModuleSettings(module);
    if (moduleSetting?.defaultUserGrade) {
      await this.userGradeService.saveUserGrade(module, {grade: 1});
    }
    return true;
  }

}
