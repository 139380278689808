import { UserGradeEnum } from '../_utils/enums/user-grade.enum';
import { ModuleEnum } from '../_utils/unums/module.enum';

export const USER_GRADE_SETTINGS = [
  {
    module: ModuleEnum.feedback,
    key: 'pre_training',
    groups: [
      {
        key: 'general',
        items: ['-NCKDxQe6b3zSUROjYXK', '-NCKDznDKSpUJuK_B05m', '-NCKE0achVxIuw42NMxQ', '-NCKE7KAZWfGU8c2US1D',
          '-NCKE9L_tWx9Av-8pN_G', '-NCKEB0ZysF1d8T8R4Dx']
      },
      {
        key: 'contextStrengthsAndWeaknesses',
        items: ['-NCKEFSr9xfvfAi7gL60', '-NCKEI7hWGhvb6e3xv9l', '-NCKEJw1ta7IcXbx_Qrl',
          '-NCKENj4PFT2YLm3RhZX', '-NCKEQ3VMg0vN8O0jwc3']
      },
      {
        key: 'clearObjectives',
        items: ['-NCKERmQ5DYOd9Q_ULP-', '-NCKETckZES6Y8_B1Z05', '-NCKEVc5kHkkWtMnqVKw', '-NCKEXL1hQqwdOsr-SQt',
          '-NCKEZ56TT2quGffq0Qo']
      },
      {
        key: 'motivateSupportAndAdapt',
        items: ['-NCKE_qVeUgOwR30U1a5', '-NCKEbUEKIyykNWBiVR2', '-NCKEcyHM7lcL5RCc8gi',
          '-NCKEeT4SgHzJfEQAOzP', '-NCKEfTc7w5kQOVIkLE']
      }
    ],
    grades: [
      {
        key: UserGradeEnum.advance,
        title: 'Advance',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20nivel%20avanzado.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20advance%20stage.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.medium,
        title: 'Medium',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20nivel%20medio.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20medium%20stage.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.beginner,
        title: 'Beginner',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20nivel%20principiante.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20beginner%20stage.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      }
    ]
  },
  {
    module: ModuleEnum.feedback,
    key: 'post_training',
    groups: [
      {
        key: 'general',
        items: ['-NCKDxQe6b3zSUROjYXK', '-NCKDznDKSpUJuK_B05m', '-NCKE0achVxIuw42NMxQ', '-NCKE7KAZWfGU8c2US1D',
          '-NCKE9L_tWx9Av-8pN_G', '-NCKEB0ZysF1d8T8R4Dx']
      },
      {
        key: 'contextStrengthsAndWeaknesses',
        items: ['-NCKEFSr9xfvfAi7gL60', '-NCKEI7hWGhvb6e3xv9l', '-NCKEJw1ta7IcXbx_Qrl',
          '-NCKENj4PFT2YLm3RhZX', '-NCKEQ3VMg0vN8O0jwc3']
      },
      {
        key: 'clearObjectives',
        items: ['-NCKERmQ5DYOd9Q_ULP-', '-NCKETckZES6Y8_B1Z05', '-NCKEVc5kHkkWtMnqVKw', '-NCKEXL1hQqwdOsr-SQt',
          '-NCKEZ56TT2quGffq0Qo']
      },
      {
        key: 'motivateSupportAndAdapt',
        items: ['-NCKE_qVeUgOwR30U1a5', '-NCKEbUEKIyykNWBiVR2', '-NCKEcyHM7lcL5RCc8gi',
          '-NCKEeT4SgHzJfEQAOzP', '-NCKEfTc7w5kQOVIkLE']
      }
    ],
    grades: [
      {
        key: UserGradeEnum.advance,
        title: 'Advance',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20nivel%20avanzado.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20advance%20stage.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.medium,
        title: 'Medium',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20nivel%20medio.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20medium%20stage.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.beginner,
        title: 'Beginner',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20nivel%20principiante.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/feedback%2Fgrades%2FFeedback%20beginner%20stage.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      }
    ]
  },
  {
    module: ModuleEnum.conversations,
    key: 'pre_training',
    groups: [
      {
        key: 'general',
        items: ['-NieKTu88lStg23ijfy3', '-NieL7yVq8VwknweI0zH', '-NieLAKMHntbw8YOEOfV', '-NieLCNE6PfJVHbAOz9w',
          '-NieLFPErzNSE2_1iwGP']
      },
      {
        key: 'effectiveCommunication',
        items: ['-NieLJw6X7A8EOup81df', '-NieLNwceAqynvDyDsLk', '-NieLRezvDaK06ushx_6',
          '-NieLUDEZczULKBqOi1y']
      },
      {
        key: 'cognitiveEmpathyVsAffectiveEmpathy',
        items: ['-NieLXGryXWo7Xp-wLsD', '-NieLZsbKP7mQRUQTBId', '-NieLcWMfU1GTb79wbBp', '-NieLsnVkRrGMwPvodlZ',
          '-NieNZZOpkou46XHFp1C', '-NieNbB6HLM_7R_Dndlo', '-NieNdOU2FFudll4yGL5']
      },
      {
        key: 'resolveProblems',
        items: ['-NieNg8z0uLU_8VZL64c', '-NieNiDcYa_0cfERTaSs', '-NieNkKbG6ivj0gZ7D2t',
          '-NieNmRzIpg7wVn3U8o-', '-NieNp3UuN7A8KcE6yLO']
      }
    ],
    grades: [
      {
        key: UserGradeEnum.advance,
        title: 'Advance',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20avanzado.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Advanced%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.medium,
        title: 'Medium',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20medio.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Medium%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.beginner,
        title: 'Beginner',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20principiante.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Beginner%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      }
    ]
  },
  {
    module: ModuleEnum.conversations,
    key: 'post_training',
    groups: [
      {
        key: 'general',
        items: ['-NieKTu88lStg23ijfy3', '-NieL7yVq8VwknweI0zH', '-NieLAKMHntbw8YOEOfV', '-NieLCNE6PfJVHbAOz9w',
          '-NieLFPErzNSE2_1iwGP']
      },
      {
        key: 'effectiveCommunication',
        items: ['-NieLJw6X7A8EOup81df', '-NieLNwceAqynvDyDsLk', '-NieLRezvDaK06ushx_6',
          '-NieLUDEZczULKBqOi1y']
      },
      {
        key: 'cognitiveEmpathyVsAffectiveEmpathy',
        items: ['-NieLXGryXWo7Xp-wLsD', '-NieLZsbKP7mQRUQTBId', '-NieLcWMfU1GTb79wbBp', '-NieLsnVkRrGMwPvodlZ',
          '-NieNZZOpkou46XHFp1C', '-NieNbB6HLM_7R_Dndlo', '-NieNdOU2FFudll4yGL5']
      },
      {
        key: 'resolveProblems',
        items: ['-NieNg8z0uLU_8VZL64c', '-NieNiDcYa_0cfERTaSs', '-NieNkKbG6ivj0gZ7D2t',
          '-NieNmRzIpg7wVn3U8o-', '-NieNp3UuN7A8KcE6yLO']
      }
    ],
    grades: [
      {
        key: UserGradeEnum.advance,
        title: 'Advance',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20avanzado.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Advanced%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.medium,
        title: 'Medium',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20medio.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Medium%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.beginner,
        title: 'Beginner',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20principiante.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Beginner%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      }
    ]
  },
  // Interviews
  {
    module: ModuleEnum.interviews,
    key: 'pre_training',
    groups: [
      {
        key: 'general',
        items: ['-NieKTu88lStg23ijfy3', '-NieL7yVq8VwknweI0zH', '-NieLAKMHntbw8YOEOfV', '-NieLCNE6PfJVHbAOz9w',
          '-NieLFPErzNSE2_1iwGP']
      },
      {
        key: 'effectiveCommunication',
        items: ['-NieLJw6X7A8EOup81df', '-NieLNwceAqynvDyDsLk', '-NieLRezvDaK06ushx_6',
          '-NieLUDEZczULKBqOi1y']
      },
      {
        key: 'cognitiveEmpathyVsAffectiveEmpathy',
        items: ['-NieLXGryXWo7Xp-wLsD', '-NieLZsbKP7mQRUQTBId', '-NieLcWMfU1GTb79wbBp', '-NieLsnVkRrGMwPvodlZ',
          '-NieNZZOpkou46XHFp1C', '-NieNbB6HLM_7R_Dndlo', '-NieNdOU2FFudll4yGL5']
      },
      {
        key: 'resolveProblems',
        items: ['-NieNg8z0uLU_8VZL64c', '-NieNiDcYa_0cfERTaSs', '-NieNkKbG6ivj0gZ7D2t',
          '-NieNmRzIpg7wVn3U8o-', '-NieNp3UuN7A8KcE6yLO']
      }
    ],
    grades: [
      {
        key: UserGradeEnum.advance,
        title: 'Advance',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20avanzado.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Advanced%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.medium,
        title: 'Medium',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20medio.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Medium%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.beginner,
        title: 'Beginner',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20principiante.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Beginner%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      }
    ]
  },
  {
    module: ModuleEnum.interviews,
    key: 'post_training',
    groups: [
      {
        key: 'general',
        items: ['-NieKTu88lStg23ijfy3', '-NieL7yVq8VwknweI0zH', '-NieLAKMHntbw8YOEOfV', '-NieLCNE6PfJVHbAOz9w',
          '-NieLFPErzNSE2_1iwGP']
      },
      {
        key: 'effectiveCommunication',
        items: ['-NieLJw6X7A8EOup81df', '-NieLNwceAqynvDyDsLk', '-NieLRezvDaK06ushx_6',
          '-NieLUDEZczULKBqOi1y']
      },
      {
        key: 'cognitiveEmpathyVsAffectiveEmpathy',
        items: ['-NieLXGryXWo7Xp-wLsD', '-NieLZsbKP7mQRUQTBId', '-NieLcWMfU1GTb79wbBp', '-NieLsnVkRrGMwPvodlZ',
          '-NieNZZOpkou46XHFp1C', '-NieNbB6HLM_7R_Dndlo', '-NieNdOU2FFudll4yGL5']
      },
      {
        key: 'resolveProblems',
        items: ['-NieNg8z0uLU_8VZL64c', '-NieNiDcYa_0cfERTaSs', '-NieNkKbG6ivj0gZ7D2t',
          '-NieNmRzIpg7wVn3U8o-', '-NieNp3UuN7A8KcE6yLO']
      }
    ],
    grades: [
      {
        key: UserGradeEnum.advance,
        title: 'Advance',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20avanzado.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Advanced%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.medium,
        title: 'Medium',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20medio.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Medium%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      },
      {
        key: UserGradeEnum.beginner,
        title: 'Beginner',
        description: 'Thank you for completing the evaluation. It is very important so that we can guide the practices to your needs.',
        urlVideo: {
          es: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FCD%20nivel%20principiante.mp4?alt=media',
          en: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o/conversations%2Fgrades%2FDC%20Beginner%20level.mp4?alt=media'
        },
        avatarSimulations: 6,
        sections: [
          'Choose practices according to your level of experience.',
          'Perform at least 6 simulations with avatar.',
          'Another 3 simulations with other people.'
        ]
      }
    ]
  },
];
