import { ModuleEnum } from '../unums/module.enum';

export const MENU_SETTINGS = [
  {
    module: ModuleEnum.feedback,
    title: 'Feedback for success',
    url: `/${ModuleEnum.feedback}/courses`,
    icon: 'construct',
    showItems: false,
    opened: false,
    items: [
      { id: 1, name: 'Introduction', url: `/${ModuleEnum.feedback}/intro`, trigger: 'feedback-intro-popover', key: 'introduction' },
      { id: 2, name: 'Best Practices', url: `/${ModuleEnum.feedback}/learning`, trigger: 'learning-intro-popover', key: 'bestPractices' },
      { id: 4, name: 'Avatar simulations', url: `/${ModuleEnum.feedback}/simulations`, trigger: 'simulations-popover' },
      {
        id: 5,
        name: 'Peers simulations',
        url: `/${ModuleEnum.feedback}/simulations-peers`,
        available: true,
        trigger: 'simulations-peers-popover',
        key: 'peers'
      },
    ],
    index: 1,
  },
  {
    module: ModuleEnum.conversations,
    title: 'Difficult conversations',
    url: `/${ModuleEnum.conversations}/courses`,
    icon: 'chatbubbles',
    showItems: false,
    opened: false,
    items: [
      { id: 1, name: 'Introduction', url: `/${ModuleEnum.conversations}/intro`, key: 'introduction' },
      { id: 2, name: 'Best Practices', url: `/${ModuleEnum.conversations}/learning`, key: 'bestPractices' },
      { id: 4, name: 'Avatar simulations', url: `/${ModuleEnum.conversations}/simulations` },
      {
        id: 5,
        name: 'Peers simulations',
        url: `/${ModuleEnum.conversations}/simulations-peers`,
        available: true,
        key: 'peers'
      },
    ],
    index: 2,
  },
  {
    module: ModuleEnum.motivation,
    title: 'Motivate from purpose',
    url: `/${ModuleEnum.motivation}/courses`,
    icon: 'bulb',
    showItems: false,
    opened: false,
    items: [
      { id: 1, name: 'Introduction', url: `/${ModuleEnum.motivation}/intro`, available: false, key: 'introduction' },
      { id: 2, name: 'Best Practices', url: `/${ModuleEnum.motivation}/learning`, available: false, key: 'bestPractices' },
      { id: 4, name: 'Avatar simulations', url: `/${ModuleEnum.motivation}/simulations` },
      {
        id: 5,
        name: 'Peers simulations',
        url: `/${ModuleEnum.motivation}/simulations-peers`,
        available: true,
        key: 'peers'
      },
    ],
    index: 3,
  },
  {
    module: ModuleEnum.salesAndServices,
    title: 'Sales & services',
    url: `/${ModuleEnum.salesAndServices}/courses`,
    icon: 'cart',
    showItems: false,
    opened: false,
    items: [
      { id: 1, name: 'Introduction', url: `/${ModuleEnum.salesAndServices}/intro`, key: 'introduction', available: false, },
      { id: 2, name: 'Best Practices', url: `/${ModuleEnum.salesAndServices}/learning`, key: 'bestPractices', available: false, },
      { id: 4, name: 'Avatar simulations', url: `/${ModuleEnum.salesAndServices}/simulations` },
      {
        id: 5,
        name: 'Peers simulations',
        url: `/${ModuleEnum.salesAndServices}/simulations-peers`,
        available: true,
        key: 'peers'
      },
    ],
    index: 2.1,
  },
  {
    module: ModuleEnum.interviews,
    title: 'Interviews',
    url: `/${ModuleEnum.interviews}/courses`,
    icon: 'people',
    showItems: false,
    opened: false,
    items: [
      { id: 1, name: 'Introduction', url: `/${ModuleEnum.interviews}/intro`, key: 'introduction' },
      { id: 2, name: 'Best Practices', url: `/${ModuleEnum.interviews}/learning`, key: 'bestPractices' },
      { id: 4, name: 'Avatar simulations', url: `/${ModuleEnum.interviews}/simulations` },
      {
        id: 5,
        name: 'Peers simulations',
        url: `/${ModuleEnum.interviews}/simulations-peers`,
        available: true,
        key: 'peers'
      },
    ],
    index: 3,
  },
  { title: 'Impactful communication', url: '/folder/Communicating with impact', icon: 'megaphone', available: false, opened: false, index: 5 },
  { title: 'Customer service', url: '/folder/Customer service', icon: 'headset', available: false, opened: false, index: 7 },
  { title: 'Performance evaluation', url: '/folder/Performance evaluation', icon: 'stats-chart', available: false, opened: false, index: 8 },
];
