import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timerFormat',
})
export class TimerFormatPipe implements PipeTransform {

  constructor() {}

  transform(seconds: number) {
    const auxSeconds = !seconds? 0: seconds;
    return new Date(auxSeconds * 1000).toISOString().substring(14, 19);
  }

}
