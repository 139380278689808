import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Storage } from '@ionic/storage-angular';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class UserScheduledSimulationsService {

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage
  ) { }

  public async userPendingScheduleToday(): Promise<any> {
    const user = await this.storage.get('user');
    const userScheduledSimulations = await this.firebaseService.getDocumentsListOnce(`/user_scheduled_simulations/${user.uid}`);
    return  userScheduledSimulations
      .filter(a => a.p2p_date)
      .filter((a) => (a.status !== 'done'))
      .filter((a) => (!a.rescheduled))
      .find((a) => moment().format('YYYY-MM-DD') === moment(a.p2p_date).format('YYYY-MM-DD'));
  }

  public async getUserPendingScheduleToday(): Promise<any> {
    const user = await this.storage.get('user');
    const userScheduledSimulations = await this.firebaseService.getDocumentsListOnce(`/user_scheduled_simulations/${user.uid}`);
    return  userScheduledSimulations
      .filter((a) => (a.status !== 'done'))
      .filter((a) => (!a.rescheduled))
      .find((a) => moment().format('YYYY-MM-DD') === moment(a.p2p_date).format('YYYY-MM-DD'));
  }
}
