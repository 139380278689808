import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserJourneyService } from '../_services/user-journey.service';

@Injectable({
  providedIn: 'root'
})
export class UserDemoGuard implements CanActivate {
  constructor(
    private userJourneyService: UserJourneyService,
    private router: Router,
  ) {

  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isDemo = await this.userJourneyService.isDemo();
    if (isDemo) {
      const currentModule = await this.userJourneyService.getCurrentModule();
      await this.router.navigate([`/${currentModule}/simulations-list`]);
      return false;
    }
    return true;
  }

}
