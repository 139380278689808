import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  module = "feedback";
  bookingListRef: AngularFireList<any>;
  bookingRef: AngularFireObject<any>;

  constructor(private db: AngularFireDatabase) { }

  createPushId() {
    return this.db.createPushId();
  }

  subscribeDocument(url: string, limit: number = null) {
    let query;
    if (limit) query = ref => ref.limitToLast(limit);

    return this.db.list(url, query).snapshotChanges().pipe(
      map((changes: any) =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      ));
  }

  getDocument(url: string, callbackObj = null): Promise<any> {
    return new Promise(resolve => {
      this.db.object(url).valueChanges().subscribe(res => {
        if (callbackObj) callbackObj.callback.call(callbackObj.self, res);
        resolve(res);
      });
    });
  }

  public valueChanges(url: string): Observable<any> {
    return this.db.object(url).valueChanges();
  }

  getDocumentByIndex(url: string, index: number) {
    return new Promise(resolve => {
      this.db.list(url, ref => ref.orderByChild('index').equalTo(index)).valueChanges().subscribe(res => {
        if (res) resolve(res[0]);
        else resolve(false)
      });
    });
  }

  getDocumentByIndexData(url: string, indexName: string, indexSearch): Promise<any> {
    return new Promise(resolve => {
      this.db.list(url, ref => ref.orderByChild(indexName).equalTo(indexSearch)).snapshotChanges().subscribe(res => {
        if (res && res[0]) {
          let val: any = res[0].payload.val();
          val.id = res[0].key;
          resolve(val);
        }
        else resolve(false)
      });
    });
  }

  getDocumentsListOnce(url: string, data: any = null): Promise<any[]> {
    let query;
    if (data) {
      if (data.orderByChild) {
        if (data.startAt && data.endAt) query = ref => ref.orderByChild(data.orderByChild).startAt(data.startAt).endAt(data.endAt);
        else if (data.startAt) query = ref => ref.orderByChild(data.orderByChild).startAt(data.startAt);
        else if (data.endAt) query = ref => ref.orderByChild(data.orderByChild).endAt(data.endAt);
        else if (data.equalTo) query = ref => ref.orderByChild(data.orderByChild).equalTo(data.equalTo);
      }
      if (data.limitToLast) {
        query = ref => ref.limitToLast(data.limitToLast);
      }
    }

    return new Promise(resolve => {
      let sub = this.db.list(url, query).snapshotChanges().pipe(
        map((changes: any) =>
          changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )).subscribe(res => { resolve(res) });

      setTimeout(() => { sub.unsubscribe() }, 10000);
    });
  }

  addDocument(url, data: any) {
    this.clean(data);
    return this.db.list(environment.dbUrl + url).push(data).then(res => {
      console.log(`Data was added to ${environment.dbUrl + url}`);
      return res;
    });
  }

  updateDocument(url: string, id: string, data: any): Promise<any> {
    if (url == '/users') data.updated_at = moment().format();

    return new Promise((resolve, reject) => {
      try {
        if (!id) id = this.db.list(environment.dbUrl + url).push({}).key;   // Create a new id Firebase

        this.db.object(environment.dbUrl + url + '/' + id).update(data).then((res) => {
          console.log(`Data was updated for ${environment.dbUrl + url}/${id}`);
          resolve(id);
        });
      }
      catch(err) {
        reject(err);
      }
    });
  }

  public setDocument(url: string, value: any): Promise<void> {
    return this.db.object(url).set(value);
  }

  deleteDocument(url: string, id:string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {

        this.db.object(environment.dbUrl + url + '/' + id).remove().then((res) => {
          resolve(url);
        });
      }
      catch(err) {
        reject(err);
      }
    });
  }

  // Save selected time-slots of the user
  setRolePlaySchedule(uid, peerId, timeslots, module) {
    return new Promise((resolve, reject) => {
      try {
        this.db.object(`${environment.dbUrl}/modules/${module}/${uid}/roleplayAvailability`).update(timeslots);
        this.db.object(`${environment.dbUrl}/modules/${module}/${peerId}/roleplayAvailability`).update(timeslots);
      }
      catch(err) {
        reject(err);
      }
    });
  }

  // When we already have the entire document url, we just need to SET, not push with a random generate firebase ID
  // We do it in simulations assessments
  // URL: user_assessments/{userID}/feedback/p2p_auto_feedback_reconducir/{simulationID}/{scheduledSimulationID}
  async setAssessmentResult(url: string, data: any) {
    return this.db.object(environment.dbUrl + url).set(data);
  }

  async addAssessmentResult(url: string, data: any, set = false) {
    if (set) {
      await this.setAssessmentResult(url, data);
      const fragments  = url.split('/');
      return fragments.pop();
    }
    else {
      const result = await this.db.list(environment.dbUrl + url).push(data);
      return result.key;
    }
  }

  addAssessment(assesmentName, userId, data: any) {
    let url = `${environment.dbUrl}/assessments/${assesmentName}/${userId}`;
    return this.db.list(url).push(data).then(res => {
      console.log(`Data was added to ${url}`);
    });
  }

  addDocumentQuestion(url, data: any) {
    this.clean(data);
    return this.db.list(url).push(data).then(res => {
      console.log(`Data was added to ${environment.dbUrl + url}`);
    });
  }

  clean(obj) {
    for (var propName in obj) {
      if (propName == 'password') {
        delete obj[propName];
        continue;
      }
      if (obj[propName] === null || obj[propName] === undefined) {
        // Delete 'null' fields so that the field will NOT be delete from the DB
        // Remove field if the field IS NOT in the array -
        if ([].indexOf(propName) == -1) delete obj[propName];
      }
      if (typeof obj[propName] == 'string') {
        obj[propName] = obj[propName].trim();
      }
      if (propName != 'phone' && propName != 'mobile' && typeof obj[propName] == 'string' && obj[propName] != '' && !isNaN(obj[propName])) {
        obj[propName] = Number(obj[propName]);
      }
      if (obj[propName] == 'object') {
        this.clean(obj[propName] )
      }

      if (typeof(obj[propName]) == 'object') this.clean(obj[propName])
    }
  }
}

export class Assessment {
  $key: string;
  name: string;
  email: string
  module: string;
}
