export const environment = {
  name: 'staging',
  version: "2.0.0429",
  features: null,
  production: false,  
  server: 'https://orca-app-97ayk.ondigitalocean.app',
  dbUrl: '',
  firebaseFunctionUrl: 'https://us-central1-simskills-io-staging.cloudfunctions.net',
  fireabseStorageUrl: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o',
  firebase: {
    apiKey: "AIzaSyD139oUotUfxyZQKTLwYw5ee6IjeRHv3V8",
    authDomain: "simskills-io-staging.firebaseapp.com",
    databaseURL: "https://simskills-io-staging-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "simskills-io-staging",
    storageBucket: "simskills-io-staging.appspot.com",
    messagingSenderId: "197895620806",
    appId: "1:197895620806:web:aec26e98acf0fc0277efc7"
  }
};