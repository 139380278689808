import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToArray'
})
export class NumberToArrayPipe implements PipeTransform {

  transform(value: number): number[] {
    if (!value) {
      return [];
    }
    const values = [];
    for (let i = 1; i <= Number(value); i++) {
      values.push(i);
    }
    return values;
  }

}
