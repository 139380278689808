import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, Subject, takeLast } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private subject = new Subject<any>();
  private events: { [key: string]: EventEmitter<any> };

  constructor() {
    this.events = {};
  }

  publishData(data: any) {
    this.subject.next(data);
  }

  getObservable(): Subject<any> {
    return this.subject;
  }


  public initEvent(eventName: string): void {
    this.events[eventName] = new EventEmitter<any>();
  }

  public setEvent(eventName: string, value?: any): void {
    this.events[eventName].emit(value);
  }

  public existEvent(eventName: string): boolean {
    return !!this.events[eventName];
  }

  public getEvent(eventName: string): Observable<any> {
    return this.events[eventName];
  }
}
